import { useEffect, useState } from 'react';
import styles from './Splash.module.css'
import MainCarousel from './MainCarousel'
import SignupForm from './SignupForm'
import Title from './Title'
import Fade from 'react-reveal';

export default function Splash() {
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    setHasLoaded(true);
  }, [])

  return (
    <div className={styles.container}>
      { hasLoaded && 
        <Fade bottom>
          <Title>Welcome to the Lab</Title>
          {/*<MainCarousel />*/}
          <h3 className={styles.title}>Our Mission</h3>
          <ul className={styles.list}>
            <li>Provide disc golfers of all levels with performance metrics that will help them improve their games</li>
            <li>Advance the science and skill of disc golf</li>
            <li>Grow the game</li>
            <li>Build and support the local community</li>
          </ul>
          <SignupForm />
        </Fade>
      }
    </div>
  )
}