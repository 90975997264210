import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Image from 'react-bootstrap/Image';
import styles from './Header.module.css';

export default function Header({ minimal=false }) {

  const navBody = 
      <>
        <Nav.Link href="/">Home</Nav.Link>
        <Nav.Link href="/shop">Shop</Nav.Link>
        <NavDropdown title="Apps">
          {/*<NavDropdown.Item href="/form-app-video">Video Form Comparison</NavDropdown.Item>*/}
          <NavDropdown.Item href="/form-app">{/*<i className="bi bi-graph-up"></i> */}Live Form Analysis</NavDropdown.Item>
        </NavDropdown>
        <NavDropdown title="Blog">
          <NavDropdown.Item href="/blog/increasing-disc-spin">Increasing Disc Spin</NavDropdown.Item>
          <NavDropdown.Item href="/blog/proper-timing">Proper Timing</NavDropdown.Item>
          <NavDropdown.Item href="/blog/effect-of-release-angle-on-disc-flight">Effect of Release Angle</NavDropdown.Item>
          <NavDropdown.Item href="/blog/improving-disc-golf-putting">Improving Putting</NavDropdown.Item>
          <NavDropdown.Item href="/blog/what-flight-numbers-mean">What Flight Numbers Mean</NavDropdown.Item>
          <NavDropdown.Item href="/blog/best-exercises-and-stretches">Best Exercises and Stretches</NavDropdown.Item>
          <NavDropdown.Item href="/blog/best-youtube-channels">Best YouTube Channels</NavDropdown.Item>
        </NavDropdown>
        <Nav.Link href="/portal/login">Portal</Nav.Link>
      </>
  ;

  const minimalNav = 
    <Navbar expand={false} className="bg-body-tertiary" variant="dark" id="topnav" fixed="top" style={{paddingTop: '0px', paddingBottom: '0px', background: 'none'}}>
      <Navbar.Brand href="/">
          <div style={{color: 'white', opacity: '0.5'}}>
            <Image src="/black_disc_transparent.png" height="30px" style={{margin: '2px'}} className={styles.rotate}></Image>
            &nbsp;&nbsp;
            <Image src="/text_black_large_transparent.png" height="30px" style={{margin: '2px'}}></Image>
          </div>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Offcanvas placement="end" variant="dark" style={{width: '250px'}}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="mr-auto">
            {navBody}
          </Nav>
        </Offcanvas.Body>
      </Navbar.Offcanvas>
    </Navbar>
  ;

  const fullNav = 
      <Navbar expand="md" className="bg-body-tertiary" id="topnav">
        <Navbar.Brand href="/">
          <Image src="/black_disc_transparent.png" height="36px" style={{margin: '2px'}} className={styles.rotate}></Image>
          &nbsp;&nbsp;
          <Image src="/text_black_large_transparent.png" height="36px" style={{margin: '2px'}}></Image>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
         <Nav className="mr-auto">
            {navBody}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
  ;


  return  (
    <>
      {minimal ? minimalNav : fullNav}
    </>
  )
}